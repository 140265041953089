import { useRouter } from "next/router"
import React from "react"
import TabBar, { TabBarProps } from "../TabBar"
import Tab from "../TabBar/Tab"

interface AuthTabBarProps extends TabBarProps {}

const AuthTabBar: React.SFC<Readonly<AuthTabBarProps>> = (props) => {
  const router = useRouter()

  const goToSignUp = () => {
    router.push("/sign-up")
  }

  const goToLogin = () => {
    router.push("/login")
  }

  const route = router.route.replace(/\/$/, "")

  return (
    <TabBar {...props}>
      <Tab
        active={route === "/sign-up" || route === "/sign-up/index"}
        onActivate={goToSignUp}
      >
        Sign up
      </Tab>
      <Tab
        active={route === "/login" || route === "/login/index"}
        onActivate={goToLogin}
      >
        Log in
      </Tab>
    </TabBar>
  )
}

export default AuthTabBar
