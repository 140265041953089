import Button from "@/components/Button"
import Input from "@/components/form/Input"
import useForm, { FormStatus } from "@/components/util/form/useForm"
import * as validator from "@/components/util/form/validators"
import * as actions from "@/store/user/asyncActions"
import classNames from "classnames"
import _ from "lodash"
import Link from "next/link"
import React, { useState } from "react"
import { connect } from "react-redux"
import style from "./style.scss"

interface LoginFormModel {
  email: string
  password: string
}

interface LoginFormProps {
  className?: string
  login: (input: LoginFormModel) => Promise<void>
  onLoggedIn?: () => void
}

const validate = (values: any) => ({
  email: validator.composeValidators(
    validator.isRequired(values.email),
    validator.isEmail(values.email)
  ),
  password: validator.composeValidators(validator.isRequired(values.password))
})

const initialValues = {
  email: "",
  password: ""
}

const LoginForm: React.SFC<Readonly<LoginFormProps>> = ({
  className,
  login,
  onLoggedIn = () => {}
}) => {
  const [loginError, setLoginError] = useState<string>()

  const onSubmit = async (formValues: LoginFormModel) => {
    setLoginError(undefined)

    try {
      await login(formValues)
      onLoggedIn()
    } catch (e) {
      setLoginError(e.message)
    }
  }

  const {
    mode,
    handleBlur,
    handleFocus,
    handleChange,
    handleSubmit,
    values,
    errors,
    touched
  } = useForm<LoginFormModel>({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues,
    validate,
    onSubmit
  })

  return (
    <div className={classNames(style.wrapper, className)}>
      <form onSubmit={handleSubmit} className={style.loginForm}>
        <Input
          label="Email"
          name="email"
          type="email"
          placeholder="Email"
          onFocus={handleFocus}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email || initialValues.email}
          error={loginError || errors.email}
          touched={touched.email}
        />

        <Input
          label="Password"
          name="password"
          type="password"
          placeholder="Password"
          onFocus={handleFocus}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password || initialValues.password}
          error={loginError || errors.password}
          touched={touched.password}
        />

        <div className={style.controls}>
          <Button
            disabled={mode === FormStatus.SUBMITTING}
            type="submit"
            label={mode === FormStatus.SUBMITTING ? "Logging in..." : "Login"}
          />
        </div>
      </form>

      <Link href="/forgot-password">
        <a className={style.forgotLink}>Forgot?</a>
      </Link>
    </div>
  )
}

export default connect(
  null,
  (dispatch: any) => ({
    login: (input: LoginFormModel) =>
      dispatch(actions.login(input)) as Promise<void>
  })
)(LoginForm)
