import classNames from "classnames"
import React from "react"
import style from "./style.scss"

export interface TabBarProps {
  className?: string
}

const TabBar: React.SFC<Readonly<TabBarProps>> = (props) => {
  return (
    <ul className={classNames(style.tabBar, props.className)}>
      {props.children}
    </ul>
  )
}

export default TabBar
