import AuthTabBar from "@/components/AuthTabBar"
import Layout from "@/components/Layout"
import LoginForm from "@/components/LoginForm"
import Logo from "@/components/Logo"
import { withNoAuthentication } from "@/lib/withAuthentication"
import { mapDispatchToProps } from "@/lib/withReduxStore"
import { NextPage } from "next"
import Head from "next/head"
import { useRouter } from "next/router"
import React from "react"
import { connect } from "react-redux"
import style from "./style.scss"

interface PageProps {}

const Login: NextPage<PageProps> = (props) => {
  const router = useRouter()

  const onLoggedIn = () => {
    router.push("/missions")
  }

  return (
    <>
      <Head>
        <title>Black Mirror - Login</title>
        <meta name="description" content="meta" />
      </Head>

      <Layout className={style.layout}>
        <Logo colour="colourPortage" />
        <LoginForm className={style.loginPanel} onLoggedIn={onLoggedIn} />
        <AuthTabBar className={style.tabBar} />
      </Layout>
    </>
  )
}

Login.getInitialProps = async ({
  reduxStore,
  query
}: any): Promise<PageProps> => {
  return query
}

export default withNoAuthentication(
  connect(
    null,
    mapDispatchToProps
  )(Login)
)
