import classNames from "classnames"
import React from "react"
import style from "./style.scss"

export interface TabProps {
  active: boolean
  onActivate: () => void
  className?: string
}

const Tab: React.SFC<Readonly<TabProps>> = (props) => {
  return (
    <li
      className={classNames(props.className, style.tab, {
        [style["tab--active"]]: props.active
      })}
      onClick={props.onActivate}
    >
      {props.children}
    </li>
  )
}

export default Tab
